import { preloadAdmin } from './adminRouteTree';
import { preloadCO2Impact } from './co2ImpactRouteTree';
import { preloadFollowup } from './followupRouteTree';
import { preloadPickups } from './pickupRouteTree';

export const preloadApps = () => {
  preloadAdmin();
  preloadPickups();
  preloadFollowup();
  preloadCO2Impact();
};
