import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import AppWrapper from '~/components/AppWrapper/AppWrapper';
import { ErrorPage } from '~/components/Error';
import { FollowupRouteTree } from './followupRouteTree';
import { AdminRouteTree } from './adminRouteTree';
import { PickupsRouteTree } from './pickupRouteTree';
import { PortalHomeRouteTree } from './portalHomeRouteTree';
import { CO2ImpactRouteTree } from './co2ImpactRouteTree';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<AppWrapper />}
      errorElement={<ErrorPage errorType="SOMETHING_WENT_WRONG" includeAppContainer={true} />}
    >
      {...PortalHomeRouteTree}
      {PickupsRouteTree}
      {CO2ImpactRouteTree}
      {FollowupRouteTree}
      {AdminRouteTree}

      <Route path="*" element={<ErrorPage errorType="PAGE_NOT_FOUND" />} />
    </Route>,
  ),
);
