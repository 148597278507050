import styled from '@emotion/styled';

import { Link, breakpoint, stenaRecycling } from 'component-library';

export const ErrorPageTextContainer = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 1440px;
`;

export const StyledSupportLink = styled(Link)`
  display: inline-block;
`;

export const StyledText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1e1e1e;
  margin-bottom: ${stenaRecycling.spacing.medium};
`;

export const ImageContainer = styled.div`
  background-position: 50% 55%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 500px;
  max-width: calc(100vw - 16px);
  transform: translateX(-50%);
  margin-left: 50%;
  flex-grow: 1;
  left: 0;
  @media (max-width: ${breakpoint.medium}px) {
    max-width: 100vw;
    min-height: 200px;
    background-position: 50% 70%;
  }

  @media (max-width: ${breakpoint.small}px) {
    background-position: 20% 65%;
  }
`;
