import { Heading } from 'component-library';
import { StyledSupportLink, StyledText } from '../styles';

export const SomethingWentWrong = () => {
  return (
    <>
      <Heading mb="xsmall" as="h2" variant="large">
        Something went wrong
      </Heading>

      <StyledText>
        Try again later. If the error persists, contact{' '}
        <StyledSupportLink key={1} href="/support">
          support
        </StyledSupportLink>
        .
      </StyledText>
    </>
  );
};
