import { AppContainer, Button, Link, PageContainer } from 'component-library';
import { useTranslation } from 'react-i18next';
import { HeaderMenu } from '@packages/core/lib/HeaderMenu';
import stenaWorkersImg from '~/assets/img/stena-workers-container.png';
import { FooterWrap } from '../AppWrapper/AppWrapper';
import { ImageContainer, ErrorPageTextContainer } from './styles';

import { PageNotFound } from './subs/PageNotFound';
import { PageNoAccess } from './subs/PageNoAccess';
import { SomethingWentWrong } from './subs/SomethingWentWrong';

export type ErrorReasonType = keyof typeof ErrorReason;

export const ErrorReason = {
  NO_ACCESS: 'NO_ACCESS',
  PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
  SOMETHING_WENT_WRONG: 'SOMETHING_WENT_WRONG',
} as const;
const Wrapper = ({
  children,
  includeAppContainer,
}: {
  includeAppContainer?: boolean;
  children: React.ReactNode;
}) => {
  if (includeAppContainer) {
    return (
      <AppContainer header={<Header />} footer={<FooterWrap />}>
        {children}
      </AppContainer>
    );
  }

  return children;
};

const Header = () => {
  const { t } = useTranslation();

  return (
    <HeaderMenu
      text={{
        homeLink: t('home.link'),
        signOut: t('sign.out'),
        userProfile: t('user.profile'),
        contactSupport: t('contact.support'),
        userManagement: '',
        co2Impact: '',
      }}
      activeServiceName="co2_impact"
      subLinks={[]}
      serviceNames={{}}
    />
  );
};

export const ErrorPage = ({
  errorType,
  includeAppContainer,
}: {
  errorType: ErrorReasonType;
  includeAppContainer?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper includeAppContainer={includeAppContainer}>
      <PageContainer>
        <ErrorPageTextContainer>
          <ErrorPageContent errorType={errorType} />
          <div>
            <Link href="/">
              <Button>{t('start.page.go.to.home')}</Button>
            </Link>
          </div>
        </ErrorPageTextContainer>
      </PageContainer>
      <ImageContainer style={{ backgroundImage: `url(${stenaWorkersImg})` }} />
    </Wrapper>
  );
};

const ErrorPageContent = ({ errorType }: { errorType: ErrorReasonType }) => {
  switch (errorType) {
    case ErrorReason.NO_ACCESS:
      return <PageNoAccess />;
    case ErrorReason.PAGE_NOT_FOUND:
      return <PageNotFound />;
    case ErrorReason.SOMETHING_WENT_WRONG:
      return <SomethingWentWrong />;
    default:
      return <PageNotFound />;
  }
};
