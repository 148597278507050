import { Heading } from 'component-library';
import { useTranslation } from 'react-i18next';
import { StyledText } from '../styles';

export const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading mb="xsmall" as="h2" variant="large">
        {t('start.page.not.found.title')}
      </Heading>

      <StyledText>{t('start.page.not.found.body')}</StyledText>
    </>
  );
};
