import { Heading } from 'component-library';
import { Trans, useTranslation } from 'react-i18next';
import { StyledSupportLink, StyledText } from '../styles';

export const PageNoAccess = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading mb="xsmall" as="h2" variant="large">
        {t('start.page.no.access.title')}
      </Heading>

      <StyledText>
        <Trans
          i18nKey="start.page.no.access.body"
          components={[<StyledSupportLink key={1} href="/support" />]}
        />
      </StyledText>
    </>
  );
};
